/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ButtonBase from '@material-ui/core/ButtonBase';
import { modules } from '@config';
import Link from 'next/link';
import React from 'react';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
// import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import dynamic from 'next/dynamic';
import Star from '@material-ui/icons/Star';
import Typography from '@common_typography';
import { useTranslation } from '@i18n';
import generateSoldProduct from '@root/src/helpers/generateSoldProduct';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import useStyles from '../style';

const PriceFormat = dynamic(() => import('@common_priceformat'), { ssr: false });
const AdditionalDiscountLabel = dynamic(() => import('@modules/home/pages/default/components/AdditionalDiscount'), { ssr: false });
// const RatingStar = dynamic(() => import('@common_ratingstar'), { ssr: false });

const Detail = (props) => {
    const {
        spesificProduct,
        handleClick,
        name,
        handleFeed,
        ratingValue,
        __typename,
        price_range,
        price_tiers,
        feed,
        special_from_date,
        special_to_date,
        vendor_product_data,
        // handleCompare,
        url_key,
        product_sold = 0,
        customGtmClass,
    } = props;
    const styles = useStyles();
    const { t } = useTranslation(['product']);
    // const getName = () => {
    //     const length = name && name.length;
    //     if (length <= 30) {
    //         return name;
    //     }
    //     return `${name.substr(0, 29)}...`;
    // };
    const vendor = vendor_product_data[0] ?? vendor_product_data;

    return (
        <div className={styles.descItem}>
            <PriceFormat
                // eslint-disable-next-line camelcase
                priceRange={spesificProduct.price_range ? spesificProduct.price_range : price_range}
                // eslint-disable-next-line camelcase
                priceTiers={spesificProduct.price_tiers ? spesificProduct.price_tiers : price_tiers}
                productType={__typename}
                specialFromDate={special_from_date}
                specialToDate={special_to_date}
                vendorCost={vendor.vendor_cost}
                vendorSpecialPrice={vendor.vendor_special_price}
                flashSalePrice={vendor.flash_sale_price}
                flashSaleFrom={vendor.flash_sale_from}
                flashSaleTo={vendor.flash_sale_to}
                vendorFlashSalePercent={vendor.flash_sale_percent}
                vendorFlashSaleDiscountAmount={vendor.flash_sale_discount_amount}
            />
            <AdditionalDiscountLabel {...props} />
            <Link prefetch={false} href="/[...slug]" as={`/${url_key}`} className={styles.productLinkButton}>
                <a
                    onClick={handleClick}
                    className={classNames(styles.title, customGtmClass)}
                >
                    {name}
                </a>
            </Link>
            <div className={styles.actions}>
                <div className={styles.ratingContainer}>
                    <div className="rating">
                        <Star />
                        <Typography className="rating-count" variant="p" type="regular" letter="capitalize">
                            {ratingValue || 5}
                        </Typography>
                    </div>
                    <Divider className={styles.divider} orientation="vertical" flexItem />
                    <div className="sold-container">
                        <Typography size={12} className="clear-margin-padding" variant="p" letter="capitalize">
                            {generateSoldProduct(product_sold)}
                            {' '}
                            {t('product:soldPlp')}
                        </Typography>
                    </div>
                </div>
                {/* <RatingStar value={ratingValue} /> */}
                {modules.wishlist.enabled && (
                    <ButtonBase className={styles.btnFeed} onClick={handleFeed}>
                        {feed ? <Favorite className={styles.iconActive} /> : <FavoriteBorderOutlined />}
                    </ButtonBase>
                )}
                {/* <ButtonBase className={styles.btnCompare} onClick={handleCompare}> */}
                {/*     <CompareArrowsIcon className={styles.icon} /> */}
                {/* </ButtonBase> */}
            </div>
        </div>
    );
};

export default Detail;
